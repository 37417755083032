export class FileConvertionsUtils {

  static base64ToFile(fileName: string, fileBase64: string): File {
    const bytesString = atob(fileBase64);
    const uInt8Array = new Uint8Array(bytesString.length);
    let charCodeIndex = bytesString.length;
    while(charCodeIndex--) {
      uInt8Array[charCodeIndex] = bytesString.charCodeAt(charCodeIndex);
    }
    
    return new File([uInt8Array], fileName, { type: 'application/pdf' });
  }

  static fileToArrayByffer(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = (e: any) => resolve(e.target.result);
      reader.readAsArrayBuffer(file);
    });
  }

  static arrayBufferToBase64(buffer: ArrayBuffer): string {
    let charCodesJoined = '';
    const documentBytes = new Uint8Array(buffer);
    for (let i = 0; i < documentBytes.byteLength; i++) {
      charCodesJoined += String.fromCharCode(documentBytes[i]);
    }
    return window.btoa(charCodesJoined);
  }

  static arrayBufferToFile(filename: string, buffer: ArrayBuffer): File {
    const blob = new Blob([buffer]);
    return new File([blob], filename);
  }
}